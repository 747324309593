import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

// assets
import './assets/scss/style.scss';

import { Provider } from 'react-redux';

// project import
import reducer from './store/reducer';
import * as serviceWorker from './serviceWorker';
import App from './layout/App';

import { createStore } from 'redux';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from './errorBoundary';
const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

// get default from environment variable else hardcoded

// ==============================|| AXIOS DEFAULTS ||===========================
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.defaults.baseURL =  'http://localhost:5001';

// axios.interceptors for adding the token to every request
axios.interceptors.request.use(
  (config) => {
    const token = 'uhe98bzs5^y1bjhd-1';
    console.log('token', token);
    // const token = sessionStorage.getItem('token') || "abcdefg";
    const fullURL = config.url.startsWith('http') ? config.url : `${axios.defaults.baseURL}${config.url}`;

    // Add token if the request URL starts with the base URL
    if (fullURL.startsWith(axios.defaults.baseURL) && token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
// ==============================|| MAIN - REACT DOM RENDER  ||==============
const root = createRoot(document.getElementById('root'));

// ==============================|| MAIN - REACT DOM RENDER  ||==============

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ToastContainer autoClose={1500} />
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
