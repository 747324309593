// action - state management
import * as actionTypes from './actions';

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

export const initialState = {
    sessionId: localStorage.getItem('sessionId'),
};

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SETSESSION:
            return {
                ...state,
                sessionId: action.sessionId
            };
        case actionTypes.SETSESSIONCLEAR:
            return {
                ...state,
                sessionId: null
            };
        default:
            return state;
    }
};

export default sessionReducer;
