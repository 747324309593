// third party
import { combineReducers } from 'redux';

// project import
import customizationReducer from './customizationReducer';
import userReducer from './userReducer';
import sessionReducer from './sessionReducer';

// ==============================|| REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  user: userReducer,
  sessionData: sessionReducer
});

export default reducer;
