import React, { useEffect, useState } from 'react';

// material-ui
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// third-party
import { useDispatch, useSelector } from 'react-redux';

// project import
import theme from '../themes';
import Routes from '../routes/index';
import NavigationScroll from './NavigationScroll';
import { useNavigate } from 'react-router-dom';
import * as actionTypes from '../store/actions';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uLoca = localStorage.getItem('user');
  const [userData, setUserData] = useState(uLoca);
  const user = useSelector((state) => state.user.isLogin);
  const sessionId = useSelector((state) => state.sessionId); // Get session ID from Redux

  useEffect(() => {
    setUserData(user);
  }, [user]);

  useEffect(() => {
    if (uLoca == '' || uLoca == null || uLoca == undefined) {
      setUserData(false);
    } else {
      setUserData(uLoca !== '' ? true : false);
    }
  }, [uLoca]);

  useEffect(() => {
    if (!userData) {
      navigate('/', { replace: true });
    } else {
      navigate(window.location.pathname, { replace: true });
      dispatch({ type: actionTypes.MENU_OPEN, isOpen: window.location.pathname });
      dispatch({ type: actionTypes.IS_LOGIN, isLogin: true });
    }
  }, [userData]);
  return (
    <>
      {
        <NavigationScroll>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme(customization)}>
              <CssBaseline />
              <Routes />
            </ThemeProvider>
          </StyledEngineProvider>
        </NavigationScroll>
      }
    </>
  );
};

export default App;
