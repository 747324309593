// action - state management
import * as actionTypes from './actions';

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

export const initialState = {
    isLogin: false,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.IS_LOGIN:
            return {
                ...state,
                isLogin: action.isLogin
            };
        case actionTypes.IS_LOGOUT:
            localStorage.clear();
            // sessionStorage.clear();
            return {
                ...state,
                isLogin: action.isLogin
            };
        default:
            return state;
    }
};

export default userReducer;
