import React, { lazy } from 'react';

// project import
import MainLayout from '../layout/MainLayout';
import Loadable from '../component/Loadable';

const DashboardDefault = Loadable(lazy(() => import('../views/Dashboard')));
const UtilsTypography = Loadable(lazy(() => import('../views/Utils/Typography')));
const SamplePage = Loadable(lazy(() => import('../views/SamplePage/SamplePage')));
const ErrorPage = Loadable(lazy(() => import('./../views/ErrorPage/ErrorPage')));
const ChatBot = Loadable(lazy(() => import('./../views/ChatBot/ChatBot')));
const Insights = Loadable(lazy(() => import('../views/Insights/Insights')));

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = () => {
  return {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        // path: '/dashboard',
        element: <DashboardDefault />
      },
      { path: '/utils/util-typography', element: <UtilsTypography /> },
      { path: '/sample-page', element: <SamplePage /> },
      { path: '/insights-page', element: <Insights /> },
      { path: '/chat-bot', element: <ChatBot /> },
      { path: '*', element: <ErrorPage /> }
    ]
  };
};
export default MainRoutes;
