import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, IconButton } from '@mui/material';

// project import
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { drawerWidth } from '../../../config.js';

// assets
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import logo from '../../../assets/images/logo1.png';

// ==============================|| HEADER ||============================== //

const Header = ({ drawerToggle }) => {
  const theme = useTheme();

  return (
    <>
      <Box width={drawerWidth}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Box>
            <Grid item>
              <Box
                mt={0.5}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  color: "white",
                  fontWeight: "bold"
                }}
              >
                <img
                  src={logo}
                  alt="Logo"
                  style={{
                    height: '40px',
                    width: 'auto'
                  }}
                />
                Talk To My Energy
              </Box>
            </Grid>
          </Box>
          {/* <Grid item>
            <IconButton
              edge="start"
              sx={{ mr: theme.spacing(1.25) }}
              color="inherit"
              aria-label="open drawer"
              onClick={drawerToggle}
              size="large"
            >
              <MenuTwoToneIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </Grid> */}
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      {/* <SearchSection theme="light" />
      <NotificationSection /> */}
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  drawerToggle: PropTypes.func
};

export default Header;
