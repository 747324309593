import React from 'react';
import { lazy } from 'react';

// project imports
import Loadable from '../component/Loadable';
import MinimalLayout from '../layout/MinimalLayout';

const AuthForm = Loadable(lazy(() => import('./../views/AuthForm/AuthForm')));

// ==============================|| AUTHENTICATION ROUTES ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      // path: '/application/login',
      path: '/',
      element: <AuthForm />
    }
  ]
};

export default AuthenticationRoutes;
